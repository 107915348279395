"use client";

import { useRouter } from "next/navigation";

export default function NotFound() {
  const router = useRouter();

  return (
    <section className="flex h-full flex-col items-center justify-center">
      <h2 className="text-center">
        Ups! No se encuentra lo que estabas buscando!
      </h2>
      <button
        className="mt-4 rounded-md bg-blue-500 px-4 py-2 text-sm text-white transition-colors hover:bg-blue-400"
        onClick={() => router.push("/dashboard")}
      >
        Volver al inicio
      </button>
    </section>
  );
}
